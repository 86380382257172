import React from 'react';
import Helmet from 'react-helmet';
import SEO from '../components/seo';
import ReactFullpage from '@fullpage/react-fullpage';
import Malarkey from '../components/malarkey';

import Header from '../components/header';
import Mouse from '../components/mouse';

import '../css/style.css';
import favicon from '../images/favicon.ico';

const anchors = ['home', 'about-me', 'get-in-touch'];

const IndexPage = () => (
  <React.Fragment>
    <SEO
      title="Freelancer, Software engineer, Javascript Enthusiast"
      keywords={[
        `Javascript`,
        `Frontend developer`,
        `Fullstack developer`,
        `Freelancer`
      ]}
      description="Nizar Oukhchi, Software Engineer, Freelance & a Javascript enthusiast, I love technology and code."
    />
    <Helmet>
      <link key="icon" rel="icon" href={favicon} />
    </Helmet>

    <ReactFullpage
      verticalCentered={false}
      anchors={anchors}
      render={({ state, fullpageApi }) => {
        return (
          <ReactFullpage.Wrapper>
            <div className="section min-h-full flex flex-col main-bg">
              <Header moveTo={() => fullpageApi.moveTo} />
              <div className="absolute pin  flex flex-col items-center justify-center">
                <div className="container text-center px-5 md:px-20">
                  <h1 className="text-3xl md:text-5xl text-white mb-10 text-shadow leading-normal">
                    Hello, my name is Nizar Oukhchi <br />
                    I'm <Malarkey /> from Morocco.
                  </h1>
                  <h5 className="text-2xl text-white font-normal text-shadow">
                    Crafting beautiful experiences for all kind of peoples.
                  </h5>
                </div>
                <Mouse moveSectionDown={() => fullpageApi.moveSectionDown()} />
              </div>
            </div>
            <div className="section min-h-full bg-grey-lightest">
              <div className="absolute pin  flex flex-col items-center justify-center">
                <div className="p-4 md:px-24">
                  <h2 className="text-2xl md:text-5xl text-grey-darker mb-5 md:mb-10">
                    About me
                  </h2>
                  <p className="text-base md:text-xl text-grey-darker mb-5 md:mb-10 text-justify leading-loose md:leading-loose">
                    Hello there, I’m Nizar Oukhchi, Software Engineer, Freelance
                    & a Javascript enthusiast. I’m a computer science major
                    who’s been enjoying coding for more than 5 years so far.
                    Currently I live in Rabat/Morocco & I'm working as a
                    technical referent for a multinational software corporation.
                    I'm experienced with all stages of software development
                    cycle, i have a passion for technology & innovation, & i'm
                    always looking to learn some new stuff.
                  </p>
                  <p className="text-base md:text-xl text-grey-darker mb-5 md:mb-10 text-justify leading-loose md:leading-loose">
                    I don’t enjoy rushing a project, I believe that the final
                    product should make both of the client and I proud. I choose
                    the projects I work on & I’m responsible for their
                    well-being from start to finish. Wanna do a project together
                    ?
                  </p>
                </div>
              </div>
            </div>
            <div className="section min-h-full contact-bg">
              <div className="absolute pin  flex flex-col items-center justify-center">
                <div className="p-4 md:px-24">
                  <h2 className="text-2xl md:text-5xl text-white mb-6 md:mb-10">
                    Get in touch
                  </h2>
                  <p className="text-base md:text-xl text-white mb-8 md:mb-10 text-justify leading-loose md:leading-loose">
                    I'm always interested by new Challenges. Whether you need
                    support with your code, or maybe you just have the seed of
                    an idea that you want to talk about, feel free to contact me
                    I'm here to help.
                  </p>
                  <div className="md:text-xl text-white mb-8">
                    <div className="text-2xl font-bold mb-2">Email</div>
                    <a
                      href="mailto:nizar.oukhchi@gmail.com"
                      className="text-base text-white no-underline"
                    >
                      nizar.oukhchi@gmail.com
                    </a>
                  </div>
                  <div className="md:text-xl text-white mb-8">
                    <div className="text-2xl font-bold mb-2">Phone</div>
                    <a
                      href="tel:+21266548 084"
                      className="text-base text-white"
                    >
                      +212 (0) 665 484 084
                    </a>
                  </div>
                  <div className="md:text-xl text-white mb-8">
                    <div className="text-2xl font-bold mb-2">Connect</div>
                    <div>
                      <ul className="flex list-reset">
                        <li className="pr-4">
                          <a
                            className="text-white text-4xl no-underline"
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://github.com/NizarOukhchi"
                          >
                            <i className="icon typcn-social-github" />
                          </a>
                        </li>
                        <li className="px-4">
                          <a
                            className="text-white text-4xl no-underline"
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.linkedin.com/in/nizaroukhchi"
                          >
                            <i className="icon typcn-social-linkedin" />
                          </a>
                        </li>
                        <li className="pl-4">
                          <a
                            className="text-white text-4xl no-underline"
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://twitter.com/NizarOukhchi"
                          >
                            <i className="icon typcn-social-twitter" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ReactFullpage.Wrapper>
        );
      }}
    />
  </React.Fragment>
);

export default IndexPage;
