import React from 'react';

const Header = () => (
  <header className="flex items-center px-5 lg:px-10 py-10 z-10">
    <div>
      <a className="text-white text-xl font-bold text-shadow">Nizar Oukhchi</a>
    </div>
    <nav className="nav ml-auto">
      <ul className="flex items-center list-reset">
        <li className="hidden md:flex items-center px-4">
          <a
            className="text-white text-base uppercase font-bold text-shadow no-underline"
            href="#about-me"
          >
            About me
          </a>
        </li>
        <li className="hidden md:flex items-center px-4">
          <a
            className="text-white text-base uppercase font-bold text-shadow no-underline"
            href="#get-in-touch"
          >
            Get in touch
          </a>
        </li>
        <li className="hidden md:flex items-center px-4">
          <a
            className="text-white text-base uppercase font-bold text-shadow no-underline"
            rel="noopener noreferrer"
            target="_blank"
            href="https://medium.com/@nizar.oukhchi"
          >
            blog
          </a>
        </li>
        <li className="px-4">
          <a
            className="text-white text-3xl text-shadow no-underline"
            target="_blank"
            rel="noopener noreferrer"
            href="https://github.com/NizarOukhchi"
          >
            <i className="icon typcn-social-github" />
          </a>
        </li>
        <li className="px-4">
          <a
            className="text-white text-3xl text-shadow no-underline"
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.linkedin.com/in/nizaroukhchi"
          >
            <i className="icon typcn-social-linkedin" />
          </a>
        </li>
        <li className="pl-4">
          <a
            className="text-white text-3xl text-shadow no-underline"
            target="_blank"
            rel="noopener noreferrer"
            href="https://twitter.com/NizarOukhchi"
          >
            <i className="icon typcn-social-twitter" />
          </a>
        </li>
      </ul>
    </nav>
  </header>
);

export default Header;
